import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {connect, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {userActions} from '../../Security/Actions';
import './Navbar.scss';
import {IAppState} from "../IAppState";

const Navbar: React.StatelessComponent = (props: any) => {
  const location = useLocation();

  const roles: string[] = useSelector((state: IAppState) => state.security.roles || [])

  return (
    <header className="main-nav">
      <nav>
        {/*<Link*/}
        {/*  to="/accounts"*/}
        {/*  title="Accounts"*/}
        {/*  className={*/}
        {/*    location.pathname.substr(0, 9) === '/accounts' ? 'active accounts' : 'accounts'*/}
        {/*  }*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={['fas', 'users']} className="icon" />*/}
        {/*</Link>*/}

        <a
          href="https://optiosbvba.pipedrive.com/"
          target="_blank"
          title="Pipedrive"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'product-hunt']} className="icon" />
        </a>

        {/*<Link*/}
        {/*  to="/orders"*/}
        {/*  title="Orders"*/}
        {/*  className={location.pathname.substr(0, 7) === '/orders' ? 'active orders' : 'orders'}*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={['fas', 'receipt']} className="icon" />*/}
        {/*</Link>*/}

        {/*<Link*/}
        {/*  to="/commissions"*/}
        {/*  title="Comissions"*/}
        {/*  className={*/}
        {/*    location.pathname.substr(0, 12) === '/commissions'*/}
        {/*      ? 'active commissions'*/}
        {/*      : 'commissions'*/}
        {/*  }*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={['fas', 'piggy-bank']} className="icon" />*/}
        {/*</Link>*/}

        {roles.includes('ROLE_TRANSLATOR') || roles.includes('ROLE_ADMIN') ? (
          <Link
            to="/translations"
            title="Translations"
            className={
              location.pathname.substr(0, 13) === '/translations'
                ? 'active translations'
                : 'translations'
            }
          >
            <FontAwesomeIcon icon={['fas', 'language']} className="icon" />
          </Link>) : null }

        <Link
          to="/products"
          title="Products"
          className={
            location.pathname.substr(0, 9) === '/products' ? 'active products' : 'products'
          }
        >
          <FontAwesomeIcon icon={['fas', 'pump-soap']} className="icon" />
        </Link>

        <Link
          to="/support"
          title="Support"
          className={location.pathname.substr(0, 8) === '/support' ? 'active support' : 'support'}
        >
          <FontAwesomeIcon icon={['fas', 'toolbox']} className="icon" />
        </Link>

        <Link
          to="/dashboard"
          title="dashboard"
          className={
            location.pathname.substr(0, 10) === '/dashboard' ? 'active dashboard' : undefined
          }
        >
          <FontAwesomeIcon icon={['fas', 'chart-line']} className="icon" />
        </Link>

        {roles.includes('ROLE_ADMIN') ? (
          <Link
              to="/modules"
              title="modules"
              className={
                location.pathname.substr(0, 10) === '/modules' ? 'active modules' : undefined
              }
          >
            <FontAwesomeIcon icon={['fas', 'th']} className="icon" />
          </Link>
        ): null }

        <Link to="/" onClick={() => props.dispatch(userActions.logout())} title="Logout">
          <FontAwesomeIcon icon={['fas', 'power-off']} className="icon logout" />
        </Link>
      </nav>
    </header>
  );
};
export default connect()(Navbar);
