import {
  faCompress,
  faExpand,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Table.scss'

interface IProps {
  dataKey: string
  collapsable?: boolean
  editable?: boolean
  sortable?: boolean
  collapsed?: boolean
  // Props below are dynamically added by CollapsibleTable.tsx
  index?: number
  onCollapse?: (columnIndex: number | undefined) => void
  onExpand?: (columnIndex: number | undefined) => void
  onSort?: (columnIndex: number | undefined, isAscending: boolean | null) => void
  children?: string | any[]
}

interface IState {
  isCollapsed: boolean
  sort: null | boolean
}

class TableColumn extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isCollapsed: !!props.collapsed,
      sort: null,
    }

    if (this.state.isCollapsed && props.onCollapse) {
      props.onCollapse(this.props.index)
    }

    this.collapse.bind(this)
    this.expand.bind(this)
  }

  expand() {
    this.setState({ isCollapsed: false })
    if (this.props.onExpand) {
      this.props.onExpand(this.props.index)
    }
  }

  collapse() {
    this.setState({ isCollapsed: true })
    if (this.props.onCollapse) {
      this.props.onCollapse(this.props.index)
    }
  }

  sort(isAscending: boolean) {
    this.setState({ sort: isAscending })
    if (this.props.onSort) {
      this.props.onSort(this.props.index, isAscending)
    }
  }

  isCollapsed() {
    return this.state.isCollapsed
  }

  render() {
    const { isCollapsed, sort } = this.state
    const { collapsable, sortable } = this.props

    return (
      <th>
        {isCollapsed ? '' : this.props.children}
        <div className="headerActions">
          {isCollapsed ? (
            <span onClick={() => this.expand()}>
              <FontAwesomeIcon icon={faExpand} />
            </span>
          ) : (
            <>
              {sortable === false ? (
                ''
              ) : sort === null ? (
                <span onClick={() => this.sort(true)}>
                  {' '}
                  <FontAwesomeIcon icon={faSort} />
                </span>
              ) : sort === true ? (
                <span onClick={() => this.sort(false)}>
                  <FontAwesomeIcon icon={faSortUp} />
                </span>
              ) : (
                <span onClick={() => this.sort(true)}>
                  <FontAwesomeIcon icon={faSortDown} />
                </span>
              )}
              {collapsable === false ? (
                ''
              ) : (
                <span onClick={() => this.collapse()}>
                  <FontAwesomeIcon icon={faCompress} />
                </span>
              )}
            </>
          )}
        </div>
      </th>
    )
  }
}

export default TableColumn
