import React, {useState, useEffect, ReactElement} from 'react';
import {TFunctionResult} from 'i18next';

interface IProps {
  label: string | false | TFunctionResult | ReactElement;
  value?: string;
  onChange?: (value: string) => void;
  full?: boolean;
  errorMessage?: string | undefined;
  format?: (value: string) => string;
  cleanFormat?: (value: string) => string;
  className?: string;
  placeholder?: string;
}

const TextInput: React.FunctionComponent<IProps> = (props: IProps) => {
  const [value, setValue] = useState(props.value);

  const classes = [props.className];
  if (props.full) classes.push('full');
  if (props.errorMessage) classes.push('error');

  let formattedValue = value;
  if (props.format && formattedValue) {
    formattedValue = props.format(formattedValue);
  }

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <label className={classes.join(' ')}>
      {props.label === false ? '' : <div className="text">{props.label}</div>}
      <input
        type="text"
        className="input"
        value={formattedValue}
        onChange={e => {
          e.preventDefault();
          let cleanedValue = e.target.value;
          if (props.cleanFormat) {
            cleanedValue = props.cleanFormat(cleanedValue);
          }

          setValue(cleanedValue);
          if (props.onChange) props.onChange(cleanedValue);
        }}
        placeholder={props.placeholder}
      />
      {props.errorMessage ? <div className="error">{props.errorMessage}</div> : ''}
    </label>
  );
};

export default TextInput;
